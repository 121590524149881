import { zodResolver } from '@hookform/resolvers/zod'
import chevronLeft from '@ingka/ssr-icon/paths/chevron-left'
import { useMutation } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { useForm } from 'react-hook-form'
import * as z from 'zod'

import { api } from '~/clients/api'
import { Button } from '~/components/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '~/components/ui/form'
import { Input } from '~/components/ui/input'
import { env } from '~/env.mjs'
import { setAccessToken } from '~/features/auth/use-user'

const formSchema = z.object({
  username: z.string().min(1),
  password: z.string().min(1),
})

export function UsernamePasswordForm({ onCancel }: { onCancel: () => void }) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: '',
    },
  })
  const router = useRouter()

  const usernameAuthentication = useMutation({
    mutationFn: async (values: z.infer<typeof formSchema>) => {
      const authorization = `Basic ${btoa(
        `${values.username}:${values.password}`,
      )}`
      return api.authentication.authenticate({
        Authorization: authorization,
        'x-client-id': env.NEXT_PUBLIC_X_CLIENT_ID,
      })
    },
    onSuccess: (response) => {
      setAccessToken(response.data.accessToken)
      window.sessionStorage.setItem('ssoCode', response.data.code)

      router.push('/waiting-room')
    },
  })

  return (
    <Form {...form}>
      <form
        className="flex w-full max-w-[520px] flex-col gap-8 rounded bg-neutral-1"
        onSubmit={form.handleSubmit((values) =>
          usernameAuthentication.mutateAsync(values),
        )}
      >
        <div className="flex flex-col gap-4">
          <Button
            type="plain"
            ssrIcon={chevronLeft}
            className="self-start"
            onClick={onCancel}
          >
            Back
          </Button>
          <FormField
            control={form.control}
            name="username"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="font-normal">Username</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Username"
                    className="rounded border-neutral-4"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="font-normal">Password</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type="password"
                    placeholder="Password"
                    className="rounded border-neutral-4"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div className="flex gap-4">
          <Button
            className="w-full"
            type="primary"
            htmlType="submit"
            loading={usernameAuthentication.isPending}
            disabled={!form.formState.isValid}
            text="Sign in"
          />
        </div>
      </form>
    </Form>
  )
}
